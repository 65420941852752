export enum HydraparkProjectFields {
    hpProductTypeAttribute = 'hpProductTypeAttribute',
    hpProfileAttribute = 'hpProfileAttribute',
    material1 = 'hpMaterial1Construction',
    hpCanFinish = 'hpCanFinish',
    material2 = 'hpMaterial2Construction',
    material3 = 'hpMaterial3Construction',
    size_ID = 'part1-size1', // A. Inner Diameter
    size_OD = 'part1-size2', // B. Outer Diameter
    size_Height = 'height', // Height
    size_groove_width = 'groovewidth', // C. Groove Width
    size_seal_groove_diameter = 'sealgroovediameter', // D. Seal Groove Diameter
    size_bearing_groove_width = 'bearinggroovewidth', // E. Bearing Groove Width
    size_shoulder_thickness_1 = 'shoulderthickness1', // F. Shoulder Thickness 1
    size_shoulder_thickness_2 = 'shoulderthickness2', // G. Shoulder Thickness 2
    notes = 'notes', // user notes to order
    usernotes = 'usernotes', // Field indicating product cant be directly ordered
    sealorgroove = 'sealorgroove', // Seal or groove selector
    // fake field
    tosAgreed = 'tosAgreed',
}
