import ProductSelectorView from '@hydrapark/views/ProductSelectorView';
import ProfileSelectorView from '@hydrapark/views/ProfileSelectorView';
import MaterialSelectorView from '@hydrapark/views/MaterialSelectorView';
import MeasurmentsView from '@hydrapark/views/MeasurmentsView';
import SummaryView from '@hydrapark/views/SummaryView';
import { TranslationKey } from '@hydrapark/utils/text-translations';
import type { IStepView } from './types';
/**
 * This is a project specific import. When switching projects, look for every import that is marked with @PROJECT
 * This one is already in the specific project folder so needs no changing, so not marked with @PROJECT
 */
import {
  STEP_CONFIRM,
  STEP_DIMENSIONS, STEP_MATERIALS, STEP_PRODUCT, STEP_PROFILE,
} from './stepNames';
import { HydraparkProjectFields } from '../project-fields';

export const VIEWS_LIST: Array<IStepView<TranslationKey>> = [{
  title: 'texts.step1.title',
  stepName: STEP_PRODUCT,
  component: ProductSelectorView,
  description: 'texts.step1.description',
  requiredFields: [
    HydraparkProjectFields.hpProductTypeAttribute,
  ],
}, {
  title: 'texts.step2.title',
  stepName: STEP_PROFILE,
  component: ProfileSelectorView,
  description: 'texts.step2.description',
  requiredFields: [
    HydraparkProjectFields.hpProfileAttribute,
  ],
},
{
  title: 'texts.step3.title',
  stepName: STEP_MATERIALS,
  component: MaterialSelectorView,
  description: 'texts.step3.description',
  requiredFields: [
    HydraparkProjectFields.material1,
    HydraparkProjectFields.material2,
    HydraparkProjectFields.material3,
    HydraparkProjectFields.hpCanFinish,
  ],
},
{
  title: 'texts.step4.title',
  stepName: STEP_DIMENSIONS,
  component: MeasurmentsView,
  description: 'texts.step4.description',
  requiredFields: [
    HydraparkProjectFields.sealorgroove,
    HydraparkProjectFields.size_ID,
    HydraparkProjectFields.size_OD,
    HydraparkProjectFields.size_Height,
    HydraparkProjectFields.size_groove_width,
    HydraparkProjectFields.size_seal_groove_diameter,
    HydraparkProjectFields.size_bearing_groove_width,
    HydraparkProjectFields.size_shoulder_thickness_1,
    HydraparkProjectFields.size_shoulder_thickness_2,
  ],
}, {
  title: 'texts.step5.title',
  stepName: STEP_CONFIRM,
  component: SummaryView,
  // eslint-disable-next-line
  description: 'texts.step5.description',
  requiredFields: [
    HydraparkProjectFields.notes,
    HydraparkProjectFields.usernotes,
  ],
}];

export const DEFAULT_ERROR_NO_APPROPRIATE_CONFIGURATION = 'texts.error.noAppropriateConfiguration';
export const DEFAULT_ERROR_NO_MESSAGE_JUST_STOP = 'texts.error.noErrorMessage';
