import type { GeneratorDropdownFieldType } from 'common/components/FieldGenerator/types';
import { FieldsType } from 'common/types/fields';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';

export function isGeneratorDropdownFieldType(field: any): field is GeneratorDropdownFieldType {
  if (
    field.type && Object.values(FieldsType).includes(field.type)
    && field.type === FieldsType.dropdown
    && field.name && Object.values(HydraparkProjectFields).includes(field.name)
    && (
      (field.value && field.value.options
        && Array.isArray(field.value.options)
        && field.value.options.length)
      || !field.value
    )
  ) {
    return true;
  }
  return false;
}
