import { useGenericFieldsData } from 'containers/GenericFieldsHandlingLayer/context';
import { useData } from 'containers/AdditionalDataStorage/context';
import { getSingleNumberValue, getSingleOption, getSingleStringValue } from 'api/cart/utils';
import { mapProjectFieldsToProjectHydraparkCart, partVariables } from '@hydrapark/api/cart/iframe';
import { IFormValues } from 'common/components/FormHF/types';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { LengthType } from 'common/types/additional';
import { toFixedByLengthType } from '../MeasurmentsView/utils';
import { isGeneratorDropdownFieldType } from '../../utils/isGeneratorFieldType';

const allSizeFields = [
  HydraparkProjectFields.size_ID,
  HydraparkProjectFields.size_OD,
  HydraparkProjectFields.size_Height,
  HydraparkProjectFields.size_groove_width,
  HydraparkProjectFields.size_seal_groove_diameter,
  HydraparkProjectFields.size_bearing_groove_width,
  HydraparkProjectFields.size_shoulder_thickness_1,
  HydraparkProjectFields.size_shoulder_thickness_2,
] as const;

export const useSummary = () => {
  const { fields: currentFields } = useGenericFieldsData<IFormValues<HydraparkProjectFields>>();
  const additionalData = useData();

  const product = getSingleOption(currentFields.hpProductTypeAttribute?.value?.selected);
  const notes = getSingleStringValue(currentFields.notes?.value?.selected);
  const profile = getSingleOption(currentFields.hpProfileAttribute?.value?.selected);
  const parts = partVariables.map((part, index) => ({
    material: getSingleOption(currentFields[part.material]?.value?.selected),
    partNo: index + 1,
  })).filter((part) => part.material);

  const sizes = allSizeFields.map((field) => ({
    title: currentFields[field]?.title,
    // eslint-disable-next-line max-len
    value: currentFields[field]?.value?.selected ? toFixedByLengthType(getSingleNumberValue(currentFields[field]?.value?.selected)!, LengthType.inches, additionalData.lengthType) : undefined,
    unit: additionalData.lengthType,
  })).filter((part) => !!part.value);

  let sealOrGrooveTitle = '';
  const sealOrGroove = currentFields.sealorgroove;
  const sealOrGrooveSelected = getSingleOption(sealOrGroove?.value?.selected);
  if (isGeneratorDropdownFieldType(currentFields.sealorgroove) && sealOrGrooveSelected) {
    sealOrGrooveTitle = currentFields.sealorgroove.value?.options?.find((option) => option.id === (sealOrGrooveSelected as any).value)?.title || '';
  }

  const { generatedConfigId } = mapProjectFieldsToProjectHydraparkCart(currentFields, additionalData);
  const quantity = additionalData.configQuantity;


  return {
    product,
    profile,
    parts,
    sizes,
    generatedConfigId,
    notes,
    quantity,
    sealOrGroove: sealOrGrooveTitle,
  };
};

export type Summary = ReturnType<typeof useSummary>;
