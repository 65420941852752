// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import type { IViewProps } from 'common/types/views';
import type { WithChildren } from 'common/types';

import { FieldsType } from 'common/types/fields';
import { LengthType, SelectedConfiguration } from 'common/types/additional';
import { useActions, useLengthTypeData } from 'containers/AdditionalDataStorage/context';

import { useGenericFieldsActionsAdopted, useGenericFieldsDataAdopted } from 'containers/FormDataAdapter';
import { determineAndApplyErrorToForm } from 'utils/determineAndApplyErrorToForm';

import FormWrapper from 'common/components/FormWrapper';
import ViewsButtonsBlock from 'common/components/ViewsButtonsBlock';
import { IFormValues } from 'common/components/FormHF/types';
import { prepareDefaultFormData } from 'common/components/FormHF/utils';
import FieldGenerator from 'common/components/FieldGenerator';
import { FormLoader } from 'common/components/Loader';

import { useIsNextStepAvailable } from 'hooks/useIsNextStepAvailable';
import { HydraparkProjectFields } from '@hydrapark/containers/project-fields';
import { isFieldDisabled } from 'utils/isStatusDisabled';
import Switch from 'common/components/Switch';
import { OptionType } from 'common/components/Select/types';
import { isGeneratorDropdownFieldType } from '@hydrapark/utils/isGeneratorFieldType';
import styles from './styles.module.scss';
import {
  toFixedByLengthType,
  useTranslateAssemblyLengthToAppropriateLengthType,
} from './utils';


const allSizeFields = [
  HydraparkProjectFields.size_ID,
  HydraparkProjectFields.size_OD,
  HydraparkProjectFields.size_Height,
  HydraparkProjectFields.size_groove_width,
  HydraparkProjectFields.size_seal_groove_diameter,
  HydraparkProjectFields.size_bearing_groove_width,
  HydraparkProjectFields.size_shoulder_thickness_1,
  HydraparkProjectFields.size_shoulder_thickness_2,
] as const;

const MaterialSelectorView = ({
  onNextClickHandler,
  onResetHandler,
  title,
  description,
  fieldsNames,
  isNextDisabled,
  children,
  clearProgressError,
  onBackClickHandler,
  handleSetNoFieldsAvailable,
}: WithChildren<IViewProps<HydraparkProjectFields>>) => {
  const idForm = 'form-material-selector';

  const { error, isLoading, order } = useGenericFieldsDataAdopted<IFormValues<HydraparkProjectFields>>();
  const { updateFieldsState, getFieldsByName } = useGenericFieldsActionsAdopted<IFormValues<HydraparkProjectFields>>();
  const { setData } = useActions();

  const fields = useMemo(
    () => getFieldsByName(fieldsNames),
    [getFieldsByName, fieldsNames],
  );

  const extraFields = useMemo(
    () => getFieldsByName([
      HydraparkProjectFields.hpProductTypeAttribute,
      HydraparkProjectFields.hpProfileAttribute,
    ]),
    [getFieldsByName],
  );

  const hpProfileAttributeSelected = extraFields[HydraparkProjectFields.hpProfileAttribute]?.value?.selected as OptionType[] | undefined;
  const profileMeasurementImage = useMemo(() => {
    const hoseSelected = hpProfileAttributeSelected;

    return hoseSelected ? hoseSelected[0].attributes?.imageb : undefined;
  }, [hpProfileAttributeSelected]);

  const defaultValues = prepareDefaultFormData(fields);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    formState: { errors },
    resetField,
    setError,
    clearErrors,
  } = useForm<IFormValues<HydraparkProjectFields>>({ defaultValues });

  const lengthType = useLengthTypeData();

  //  We sure that this view have to contain this field and for data transformation while changing length type
  //  we need to set type of this field
  //  But if field type will be changed on the Rules-engine side - there are will be a problem
  // NOTE: This is a bit fragile and relies on the allFields array to be exact sequence of fields
  const sizesWatched = watch(allSizeFields) as [
    number,
    number,
    number,
    number,
    number,
    number,
    number,
    number,
  ];

  // NOTE: This is a bit fragile and relies on the allFields array to be exact sequence of fields with constant length
  // This ensures static amount of hooks, that's extremely important
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const fieldsAdapted = allSizeFields.map((fieldName) => useTranslateAssemblyLengthToAppropriateLengthType(
    fieldName,
    fields,
    lengthType,
  ));

  const sealOrGrooveField = fields[HydraparkProjectFields.sealorgroove];

  const sealOrGrooveParams = useMemo(() => {
    if ((sealOrGrooveField && !isFieldDisabled(sealOrGrooveField) && isGeneratorDropdownFieldType(sealOrGrooveField))) {
      const option1 = sealOrGrooveField.value?.options?.[0];
      const option2 = sealOrGrooveField.value?.options?.[1];
      const selected = sealOrGrooveField.value?.selected?.[0].title;

      return {
        option1: option1!,
        option2: option2!,
        selected,
      };
    }
    return undefined;
  }, [sealOrGrooveField]);

  const onSealOrGrooveChange = useCallback(
    (selected: string) => {
      if ((sealOrGrooveField && !isFieldDisabled(sealOrGrooveField) && isGeneratorDropdownFieldType(sealOrGrooveField))) {
        if (clearProgressError) {
          clearProgressError();
        }
        const option = sealOrGrooveField.value?.options?.find((o) => o.title === selected);
        updateFieldsState(FieldsType.dropdown, HydraparkProjectFields.sealorgroove, option);
      }
    },
    [updateFieldsState, clearProgressError],
  );

  useEffect(() => {
    setData('activeSelectedConfiguration', SelectedConfiguration.profile);

    return () => setData('activeSelectedConfiguration', null);
  }, [setData]);


  const onLengthTypeChange = useCallback(
    (lType: LengthType) => {
      console.log('onLengthTypeChange', lType);
      allSizeFields.forEach((fieldName, fieldIndex) => {
        const valueWatched = sizesWatched[fieldIndex];
        if (fields[fieldName]?.status !== 'disabled') {
          setValue(
            fieldName,
            toFixedByLengthType(
              Number(valueWatched),
              lType,
              lType === LengthType.inches ? LengthType.feet : LengthType.inches,
            ),
          );
          clearErrors(fieldName);
        }
      });
      setData('lengthType', lType);
    },
    [setValue, ...sizesWatched, clearErrors, setData],
  );

  // eslint-disable-next-line no-unused-vars
  const onSubmitSuccess: SubmitHandler<IFormValues<HydraparkProjectFields>> = useCallback((data) => {
    onNextClickHandler();
  }, [onNextClickHandler]);


  const handleFieldUpdate = useCallback((
    fieldType: FieldsType,
    fieldName: HydraparkProjectFields,
    changedValue?: IFormValues<HydraparkProjectFields>[keyof IFormValues<HydraparkProjectFields>],
  ) => {
    if (clearProgressError) { clearProgressError(); }
    if (allSizeFields.includes(fieldName as typeof allSizeFields[number])) {
      updateFieldsState(
        fieldType,
        fieldName,
        changedValue && toFixedByLengthType(Number(changedValue), lengthType, LengthType.inches),
      );
    } else {
      updateFieldsState(fieldType, fieldName, changedValue);
    }
  }, [clearProgressError, updateFieldsState, lengthType]);

  useEffect(() => {
    if (error) {
      determineAndApplyErrorToForm(error, setError);
    }
  }, [error, setError]);

  /** This will only check enabled fields, we are allowing for disabled fields to exist */
  const fieldNamesToCheck = fieldsNames.filter((fieldName) => fields[fieldName]?.status !== 'disabled');
  useIsNextStepAvailable(order, fieldNamesToCheck, fields, handleSetNoFieldsAvailable);

  return (
    <FormWrapper
      header={title}
      description={description}
    >
      <form
        id={idForm}
        onSubmit={handleSubmit(onSubmitSuccess)}
      >
        {isLoading && <FormLoader />}
        {profileMeasurementImage && <img className={styles.image} src={profileMeasurementImage} alt="Measurement Help" />}
        <div className={styles.switches}>
          <Switch<LengthType>
            id="switch-lengthType"
            className={styles.switchWrapper}
            label1={LengthType.inches}
            label2={LengthType.centimeters}
            defaultVal={lengthType}
            onChange={onLengthTypeChange}
          />

          { sealOrGrooveParams && (
            <Switch<string>
              id="switch-sealOrGroove"
              className={styles.switchWrapper}
              label1={sealOrGrooveParams.option1.title}
              label2={sealOrGrooveParams.option2.title}
              defaultVal={sealOrGrooveParams.selected}
              onChange={onSealOrGrooveChange}
            />
          )}
        </div>

        <div className={styles.grid}>
          {fieldsAdapted && fieldsAdapted.map((field) => {
            if (
              field
            && fieldsNames.includes(field.name as HydraparkProjectFields)
              && !!fields[field.name as HydraparkProjectFields]
              && !isFieldDisabled(fields[field.name as HydraparkProjectFields])
            ) { // Note addition of isFieldDisabled(fields[fieldName]) here
              return (
                <FieldGenerator<IFormValues<HydraparkProjectFields>>
                  field={field}
                  name={field.name as HydraparkProjectFields}
                  control={control}
                  watch={watch}
                  setValue={setValue}
                  getFieldState={getFieldState}
                  key={field.name}
                  onChangeHandler={handleFieldUpdate}
                  isDisabled={false}
                  resetField={resetField}
                />
              );
            }
            return null;
          })}
        </div>
        {children}
      </form>
      <ViewsButtonsBlock
        idForm={idForm}
        onResetClickHandler={onResetHandler}
        isNextDisabled={isNextDisabled || !!error}
        onBackClickHandler={onBackClickHandler}
      />
    </FormWrapper>
  );
};

// @ts-ignore
export default MaterialSelectorView;
